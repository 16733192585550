
const subsriptions = {};

export const emmit = (channel, payload) => {
    if (subsriptions[channel]) {
        subsriptions[channel].forEach(subscription => {
            subscription(payload);
        });
    };
}

export const subscribe = (channel, cb) => {
    if (!subsriptions[channel]) {
        subsriptions[channel] = [];
    }
    subsriptions[channel].push(cb);
    return () => {
        subsriptions[channel] = subsriptions[channel].filter(item => item !== cb);
    }
}

window.emmit = emmit;