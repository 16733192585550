import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

const LanguagePicker = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetch('./locales/languages.json')
      .then(response => response.json())
      .then(async (data) => {
        const formattedOptions = await Promise.all(data.map(async lang => {
          const FlagComponent = await import(`country-flag-icons/react/3x2/${lang.code}`);
          return {
            value: lang.value,
            label: (
              <>
                <FlagComponent.default style={{ width: '24px', height: '16px', marginRight: '8px' }} />
                {lang.label}
              </>
            )
          };
        }));
        setOptions(formattedOptions);
      });
  }, []);

  const changeLanguage = (selectedOption) => {
    i18n.changeLanguage(selectedOption.value);
    dispatch({ type: 'setLanguage', language: selectedOption.value });
  };

  const customSingleValue = ({ data }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {data.label.props.children[0]}
    </div>
  );

  return (
    <Select
      value={options.find(option => option.value === language)}
      onChange={changeLanguage}
      options={options}
      components={{ SingleValue: customSingleValue }}
    />
  );
};

export default LanguagePicker;
