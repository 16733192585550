import i18next from 'i18next';
import { useEffect } from 'react';
import { createStore } from 'redux'

const initialState = {
  sidebarShow: 'responsive',
  asideShow: false,
  darkMode: false,
  language: localStorage.getItem('language') || 'en',
}


const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    case 'setLanguage':
      localStorage.setItem('language', rest.language);
      i18next.changeLanguage(rest.language);
      return { ...state, language: rest.language };
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
