export const AUTH_TOKEN = 'authenticationToken';

export const AUTHORITIES = {
    ADMIN: 'ROLE_ADMIN',
    USER: 'ROLE_USER',
    ASISTAN: 'ROLE_ASISTAN',
    ESTABLISHMENT_MANAGER: 'ROLE_ESTABLISHMENT_MANAGER',
};

export const PERMISSION_TO_ADD = true

String.prototype.turkishToUpper = function(){
  var string = this;
  var letters = { "i": "İ", "ş": "Ş", "ğ": "Ğ", "ü": "Ü", "ö": "Ö", "ç": "Ç", "ı": "I" };
  string = string.replace(/(([iışğüçö]))+/g, function(letter){ return letters[letter]; })
  return string.toUpperCase();
}

String.prototype.turkishToLower = function(){
  var string = this;
  var letters = { "İ": "i", "I": "ı", "Ş": "ş", "Ğ": "ğ", "Ü": "ü", "Ö": "ö", "Ç": "ç" };
  string = string.replace(/(([İIŞĞÜÇÖ]))+/g, function(letter){ return letters[letter]; })
  return string.toLowerCase();
}

String.prototype.firstN = function(n){
  var string = this;

  var result = (string.length > n) ? string.substring(0,n) + '...' : string
  return result
}

/*
Object.defineProperty(String.prototype, "firstN", {
  get: function () {
    return function (n) {
      return this.length > n ? this.substring(0, n) + "..." : this;
    };
  },
});
 */

export const convertToOptions = (list, selector = null) => {
  return list.map(item => (
    {
      "label": selector ? item[selector] : item,
      "value": selector ? item[selector] : item
    })
)}

export const kapsamList = {
  data: ["ULUSAL", "ULUSLARARASI"],
};

// Assistant Relations - Article
export const qValueList = {
    data: ["Q1", "Q2", "Q3", "Q4"],
  };

// Assistant Relations - Article
export const makaleTurList = {
  data: [
    "Orijinal Araştırma",
    "Derleme",
    "Editöre Mektup",
    "Vaka Sunumu",
    "Davetli Yazar",
  ],
};

// Assistant Relations - Article
export const indexList = {
  data: [
    "SCI",
    "SCIExp",
    "SSCI",
    "AHCI",
    "ESCI",
    "SCIE",
    "SSCI",
    "SCOPUS",
    "ULAKBIM",
    "DİZİN",
    "Diğer",
  ],
}

// Assistant Relations - Publication
export const yayinTurList = {
  data: [
    "Kitap",
    "Dergi"
  ],
};

// Assistant Relations - Publication
export const yayinGorevList = {
  data: [
    "Editör",
    "Çevirmen",
    "Bölüm Yazarı",
    "Yayın Kurulu Üyesi",
    "Diğer"
  ],
};

// Assistant Relations - Patent Tür
export const patentTurList =  {data: ["PATENT", "LİSANS", "PROJE"]}

export const genderDataSet = [
  {
    key: "Female",
    value: "Kadın",
  },
  {
    key: "Male",
    value: "Erkek",
  },
  {
    key: "Other",
    value: "Diğer",
  },
];

export const nationalityList =  {
  "data": [
    {
      "value": "T.C.",
      "label": "TC"
    },
    {
      "value": "K.K.T.C.",
      "label": "KKTC"
    },
    {
      "value": "YABANCI",
      "label": "FOREIGN"
    },
  ]
}
