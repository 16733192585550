import axios from 'axios';

const instance = axios.create({
    baseURL: '/rest/v1/'
});

const TIMEOUT = 10 * 60 * 1000;
instance.defaults.timeout = TIMEOUT;

window.j = instance;


export default instance;