import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CAlert,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { logout } from '../../util/securityUtil';
import { useHistory } from 'react-router-dom';
import { resetPasswordInit } from '../../api/accountResource';
import { useTranslation } from 'react-i18next';
import LanguagePicker from '../../components/LanguagePicker/LanguagePicker';

const ForgotPassword = () => {

  useEffect(() => {
    logout();
  }, []);

  const [username, setUsername] = useState('');
  const [passwordSend, setPasswordSend] = useState(false)
  const { t } = useTranslation();

  let history = useHistory();

  const handleSubmit = async () => {
    await resetPasswordInit(username);
    setPasswordSend(true);
  }

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
        </CRow>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <div className="justify-content-center" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => history.push("/")}>
                    <img className="img-responsive" src={process.env.PUBLIC_URL + '/assets/img/tkdcd_logo.png'} alt="Logo" />
                  </div>
                  <br />
                  <CForm>
                    <h1>{t('account.forgot_password')}</h1>
                    <div
                      className="justify-content-end float-right"
                      style={{ marginTop: "-43px" }}
                    >
                      <LanguagePicker />
                    </div>
                    <p className="text-muted">{t('account.send_reset_link_info')}</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="text" disabled={passwordSend} placeholder={t('account.username_placeholder')} autoComplete="off" value={username} onChange={e => setUsername(e.target.value)} />
                    </CInputGroup>
                    {
                      passwordSend &&
                      <CAlert color="success">
                        {t('account.password_reset_link_sent')}
                      </CAlert>
                    }
                    {!passwordSend && <CRow>
                      <CCol xs="6">
                        <CButton color="primary" className="px-4" onClick={handleSubmit} disabled={!username}>{t('account.confirm')}</CButton>
                      </CCol>
                      <CCol xs="6" className="text-right">
                        <CButton color="link" className="px-0" onClick={() => history.push("/login")}>{t('account.signin')}</CButton>
                      </CCol>
                    </CRow>}
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ForgotPassword;
