import React, { useState, useEffect, useCallback } from "react";
import { CToast, CToastBody, CToastHeader, CToaster } from "@coreui/react";
import { subscribe, emmit } from "../../util/eventEmitter";
import { useTranslation } from "react-i18next";

let showSuccess, showError, showWarning, showInfo;

const ToastProvider = () => {
  const { t } = useTranslation();

  showSuccess = ({ body, title } = {}) => {
    emmit("TOAST", {
      type: "success",
      body: body || t('component.toaster.success.body'),
      title: title || t('component.toaster.success.title'),
    });
  };

  showError = ({ body, title } = {}) => {
    emmit("TOAST", {
      type: "error",
      body: body || t('component.toaster.error.body'),
      title: title || t('component.toaster.error.title'),
    });
  };

  showWarning = ({ body, title } = {}) => {
    emmit("TOAST", {
      type: "warning",
      body: body || t('component.toaster.warning.body'),
      title: title || t('component.toaster.warning.title'),
    });
  };

  showInfo = ({ body, title } = {}) => {
    emmit("TOAST", {
      type: "info",
      body: body || t('component.toaster.info.body'),
      title: title || t('component.toaster.info.title'),
    });
  };

  return null;
};

const Toaster = () => {
  const [toasts, setToasts] = useState([]);

  const addToast = useCallback(
    (payload) => {
      const { body, title, type } = payload;
      setToasts((prevToasts) => [
        ...prevToasts,
        {
          position: "top-right",
          autohide: 8000,
          closeButton: true,
          fade: true,
          body,
          title,
          type,
        },
      ]);
    },
    []
  );

  useEffect(() => {
    return subscribe("TOAST", addToast);
  }, [addToast]);

  window.addToast = addToast;

  const toasters = (() => {
    return toasts.reduce((toasters, toast) => {
      toasters[toast.position] = toasters[toast.position] || [];
      toasters[toast.position].push(toast);
      return toasters;
    }, {});
  })();

  return (
    <>
      <ToastProvider />
      {Object.keys(toasters).map((toasterKey) => (
        <CToaster position={toasterKey} key={"toaster" + toasterKey}>
          {toasters[toasterKey].map((toast, key) => {
            return (
              <CToast
                key={"toast" + key}
                show={true}
                autohide={toast.autohide}
                fade={toast.fade}
              >
                <CToastHeader
                  closeButton={toast.closeButton}
                  style={{
                    background: toast.type === "success" ? "green" : "red",
                    color: "white",
                  }}
                >
                  {toast.title}
                </CToastHeader>
                <CToastBody>{toast.body}</CToastBody>
              </CToast>
            );
          })}
        </CToaster>
      ))}
    </>
  );
};

export { showSuccess, showError, showWarning, showInfo };
export default Toaster;
