import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isAuthorized, isAuthenticated } from '../../util/securityUtil';

const PrivateRoute = ({
    render: Component,
    authorities,
    ...props
}) => {

    const checkAuthorities = props => {

        if (isAuthorized(authorities)) {
            return <Component {...props} />;
        }

        return (
            <Redirect
            to={{
                pathname: '/401',
                search: props.location.search,
                state: { from: props.location },
            }}
        />
        )
    }

    const renderRedirect = props => {

        if (isAuthenticated()) {
            return checkAuthorities(props);
        } else {
            return (
                <Redirect
                    to={{
                        pathname: '/login',
                        search: props.location.search,
                        state: { from: props.location },
                    }}
                />
            )
        }
    }

    return <Route {...props} render={renderRedirect} />;
};

export default PrivateRoute;