import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { freeSet, flagSet, brandSet } from "@coreui/icons";
import { logo } from "./assets/icons/logo";

import { Provider } from "react-redux";
import store from "./store";
import setupAxiosInterceptors from "./api/config/axios-interceptor";
import { clearAuthToken } from "./util/securityUtil";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "./i18n";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

setupAxiosInterceptors(() => {
  clearAuthToken();
  window.location = "#login";
});

React.icons = { ...freeSet, ...flagSet, ...brandSet, logo };
ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
        <App />
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
